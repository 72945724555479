import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SuperButton from '../../../components/SuperButton';
import { toastFormikErrors } from '../../../utils';
import { updateUserPassword } from '../../../api/account';

export default function ChangePasswordPage() {
  const formik = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema: Yup.object({
      current_password: Yup.string()
        .required('Podanie aktualnego hasła jest wymagane.'),
      new_password: Yup.string()
        .required('Nowe hasło jest wymagane')
        .min(8, 'Nowe hasło musi mieć 8 lub więcej znaków.'),
      confirm_new_password: Yup.string()
        .required('Potwierdzenie nowego hasła jest wymagane')
        .min(8, 'Potwierdzenie nowego hasła musi mieć 8 lub więcej znaków.')
        .oneOf([Yup.ref('new_password')], 'Nowe hasło i jego potwierdzenie muszą być takie same.'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      updateUserPassword(values).then((data) => {
        if (data?.errors) {
          toast.error(data?.message);
        } else {
          toast.success(data.message);
          formik.resetForm();
        }
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  return (
    <div>
      <h1 className="h3 pb-5">Zmień hasło</h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label htmlFor="current_password">Aktualne hasło:</label>
          <input type="password" className="form-control" id="current_password" {...formik.getFieldProps('current_password')} />
        </div>

        <div className="mb-4">
          <label htmlFor="new_password">Nowe hasło:</label>
          <input type="password" className="form-control" placeholder="+8 znaków" id="new_password" {...formik.getFieldProps('new_password')} />
        </div>

        <div className="mb-4">
          <label htmlFor="confirm_new_password">Potwierdzenie nowego hasła:</label>
          <input type="password" className="form-control" placeholder="+8 znaków" id="confirm_new_password" {...formik.getFieldProps('confirm_new_password')} />
        </div>

        <SuperButton type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} className="btn btn-primary" onClick={() => toastFormikErrors(formik.errors)}>
          <FontAwesomeIcon icon={faFloppyDisk} />
          {' '}
          Zmień hasło
        </SuperButton>
      </form>
    </div>
  );
}
