export default function Avatar({ src, size = 60 }) {
  return (
    <img
      alt="Avatar"
      src={src}
      className="text-secondary bg-white border d-flex justify-content-center align-items-center"
      style={{
        display: 'inline-block',
        borderRadius: '100%',
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
}
