import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useState } from 'react';
import TablerIcon from '@components/TablerIcon';
import { IconPlus } from '@tabler/icons-react';
import SectionLoading from '../../components/SectionLoading';
import { deletePlan } from '../../api/admin';
import { useDashboardPlans, useSettings } from '../../hooks';
import { useOffCanvas } from '../../hooks/templates';
import CustomOffCanvas from '../../components/CustomOffCanvas';
import AddPlanForm from '../../components/dashboard/forms/AddPlanForm';
import EditPlanForm from '../../components/dashboard/forms/EditPlanForm';
import { datetimeFormat } from '../../utils';

export default function PackagesPage() {
  const queryClient = useQueryClient();
  const {
    isLoading, isError, error, plans,
  } = useDashboardPlans();
  const {
    open, close, offCanvasProps,
  } = useOffCanvas();
  const {
    open: editOpen, close: editClose, offCanvasProps: editPlanOffCanvasProps,
  } = useOffCanvas();
  const { isLoading: settingsIsLoading, settings } = useSettings();
  const [planToEdit, setPlanToEdit] = useState(0);

  if (isLoading || settingsIsLoading) {
    return <SectionLoading />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this plan?',
      html: '',
      icon: 'warning',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No, never mind!',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlan(id).then((data) => {
          if (!data?.errors) {
            queryClient.invalidateQueries('admin.plans');
            toast.success(data?.message);
          } else {
            toast.error(data?.message);
          }
        }); // delete plan by ID
      }
    });
  };

  const handleEditPlan = (planId) => {
    setPlanToEdit(planId);
    editOpen(true);
  };

  return (
    <>
      <h1 className="mb-3">Plans</h1>
      <div className="d-flex flex-row-reverse gap-3 mb-4">
        <button type="button" className="btn btn-primary" onClick={open}>
          <TablerIcon icon={IconPlus} stroke={1.25} />
          {' '}
          New Plan
        </button>
      </div>

      <div className="row">
        <div className="col-12">
          <section className="bg-light rounded p-4">
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Popular</th>
                  <th>Status</th>
                  <th>Created at</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {plans?.map((plan, i) => (
                  <tr key={i}>
                    <td>
                      {plan.name}
                      {' '}
                      {plan.is_free ? <span className="badge bg-success">Free</span> : ''}
                    </td>
                    <td>
                      {plan.price}
                      {' '}
                      {settings?.CURRENCY_SYMBOL}
                    </td>
                    <td>
                      {plan.is_popular ? (
                        <span className="badge text-bg-success">Yes</span>
                      ) : (
                        <span className="badge text-bg-warning">No</span>
                      )}
                    </td>
                    <td>
                      {plan.status ? (
                        <span className="badge text-bg-success">Active</span>
                      ) : (
                        <span className="badge text-bg-warning">Inactive</span>
                      )}
                    </td>
                    <td>{datetimeFormat(plan.created_at)}</td>
                    <td>
                      <button type="button" onClick={() => handleEditPlan(plan.id)} className="btn btn-primary btn-sm  mx-1 mb-1"><FontAwesomeIcon icon={faPen} /></button>
                      <button type="button" onClick={() => handleDelete(plan.id)} className="btn btn-danger btn-sm mx-1 mb-1"><FontAwesomeIcon icon={faTrash} /></button>
                    </td>
                  </tr>
                ))}

                {!plans.length && (<tr><td colSpan={6} className="text-center">N/A</td></tr>)}
              </tbody>
            </table>
          </section>
        </div>
      </div>

      <CustomOffCanvas title="New Plan" placement="end" {...offCanvasProps}>
        <AddPlanForm close={close} />
      </CustomOffCanvas>

      <CustomOffCanvas title="Edit Plan" placement="end" {...editPlanOffCanvasProps}>
        <EditPlanForm close={editClose} planId={planToEdit} />
      </CustomOffCanvas>
    </>
  );
}
