import GeneralSettings from '../../components/dashboard/forms/GeneralSettings';
import { useDashboardSettings } from '../../hooks';
import SectionLoading from '../../components/SectionLoading';
import SMTPSettings from '../../components/dashboard/forms/SMTPSettings';

export function SettingsPage() {
  const {
    isLoading, settings,
  } = useDashboardSettings();

  if (isLoading || !Object.keys(settings).length) {
    return <SectionLoading />;
  }

  return (
    <>
      <h1 className="mb-3">Settings</h1>

      <div className="row">
        <div className="col-12">
          <section className="bg-light rounded p-4">
            <div className="row">
              <div className="col-md-3 mb-3">
                <div className="nav flex-column nav-pills me-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">General</button>
                  <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">SMTP</button>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-start">
                  <div className="tab-content" id="v-pills-tabContent" style={{ width: '100%' }}>
                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                      <GeneralSettings settings={settings} />
                    </div>
                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                      <SMTPSettings settings={settings} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
