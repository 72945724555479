import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Heading from '../components/Heading';
import BasePage from './layouts/BasePage';
import SectionLoading from '../components/SectionLoading';
import { usePage } from '../hooks';
import NotFoundPage from './NotFoundPage';

export default function PagePage() {
  const { slug } = useParams();
  const {
    isLoading, isError, error, page = {},
  } = usePage(slug);

  if (isError) {
    if (error.response.status === 404) {
      return <NotFoundPage />;
    }

    toast.error(error.message);
  }

  if (isLoading || !Object.keys(page).length) {
    return <SectionLoading center />;
  }

  return (
    <BasePage>
      <Heading title={page.title} subTitle={`Strona główna > ${page.title}`} />

      {/* eslint-disable-next-line react/no-danger */}
      <article className="container my-5 px-5" style={{ minHeight: '300px' }} dangerouslySetInnerHTML={{ __html: page.content }} />
    </BasePage>
  );
}
