import { useQuery } from 'react-query';
import {
  currentQuota, getChatRoom, getUserChatRoomList, getUserOrders,
} from '../api/account';

export function useCurrentQuota(isAuthenticated = true) {
  const { data, ...rest } = useQuery('user.quota', currentQuota, {
    // staleTime: Infinity
    retry: 0,
    enabled: isAuthenticated,
  });

  return { ...rest, quota: data?.quota };
}

export function useUserOrders() {
  const { data, ...rest } = useQuery('user.orders', getUserOrders, { staleTime: Infinity });

  return { ...rest, orders: data?.orders };
}

export function useUserChatRoomList() {
  const { data, ...rest } = useQuery('user.chat.list', getUserChatRoomList, {
    // staleTime: Infinity
  });

  return { ...rest, userChatRoomList: data?.chats };
}

export default function useChatRoom(uuid) {
  const { data, ...rest } = useQuery(`user.chat.${uuid}`, () => getChatRoom(uuid), {
    staleTime: Infinity,
  });

  return { ...rest, chat: data?.chat };
}
