import { useCallback, useEffect } from 'react';
import { useLocalStore, useNaiveLocalStorage } from '.';
import { auth, googleAuth, logout } from '../api/auth';

export function useAuth() {
  const [user, setUser] = useLocalStore('user', {});
  const [getToken, setToken] = useNaiveLocalStorage('tk', '');
  const token = getToken();

  useEffect(useCallback(() => {
    if (token === '' && user?.id !== undefined) {
      setUser({});
    }
  }, []));

  const Authenticate = useCallback(async (email, password) => {
    const data = await auth(email, password);

    if (!data?.errors) {
      setUser(data.user);
      setToken(data.token);
    }

    return data;
  }, []);

  const GoogleAuthenticate = useCallback(async (googleToken) => {
    const data = await googleAuth(googleToken);

    if (!data?.errors) {
      setUser(data.user);
      setToken(data.token);
    }

    return data;
  }, []);

  const Logout = useCallback(async () => {
    // to inform the database
    await logout();

    setUser({});
    setToken('');
  }, []);

  return {
    user, setUser, Authenticate, Logout, token, GoogleAuthenticate,
  };
}

export function useUser() {
  const { user, setUser } = useAuth();

  return {
    isAuthenticated: !!user?.id, isAdmin: user?.role === 1, user, setUser,
  };
}
