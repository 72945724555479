import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../hooks/auth';

export default function NoAuthRequired() {
  const { isAuthenticated } = useUser();

  if (isAuthenticated) return <Navigate to="/" replace />;

  return (
    <Outlet />
  );
}
