import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../hooks/auth';

export default function UserRequired() {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) return <Navigate to="/login" replace />;

  return (
    <Outlet />
  );
}
