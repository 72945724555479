import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Heading from '../components/Heading';
import BasePage from './layouts/BasePage';
import SuperButton from '../components/SuperButton';
import { toastFormikErrors } from '../utils';
import { contactUs } from '../api';

export default function ContactPage() {
  const formik = useFormik({
    initialValues: {
      subject: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Temat jest wymagany.')
        .min(6, 'Temat jest zbyt krótki.'),
      email: Yup.string().email('Nieprawidłowy adres email.'),
      message: Yup.string().required('Wiadomość jest wymagana.')
        .min(20, 'Wiadomość jest zbyt krótka.')
        .max(512, 'Wiadomość jest zbyt długa.'),
    }),
    onSubmit: (values) => {
      contactUs(values).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          formik.resetForm();
        } else toast.error(data.message);
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  return (
    <BasePage>
      <Heading title="Kontakt" subTitle="Napisz do nas" />
      <section className="container">
        <form onSubmit={formik.handleSubmit}>
          <div className="row py-5">
            <div className="col-md-5 m-auto p-5">
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">Temat:</label>
                <input type="subject" id="subject" className="form-control" {...formik.getFieldProps('subject')} autoComplete="off" />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input type="text" id="email" className="form-control" {...formik.getFieldProps('email')} />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Wiadomość:</label>
                <textarea id="message" className="form-control" rows={5} {...formik.getFieldProps('message')} />
              </div>
              <SuperButton type="submit" isLoading={formik.isSubmitting} className="btn btn-primary btn-block my-4" onClick={() => toastFormikErrors(formik.errors)}>
                <FontAwesomeIcon icon={faPaperPlane} />
                {' '}
                Wyślij
              </SuperButton>
            </div>
          </div>
        </form>
      </section>
    </BasePage>
  );
}
