import {
  useCallback, useEffect, useReducer, useRef, useState,
} from 'react';
import { useQuery } from 'react-query';
import {
  getDashboardSettings, getPage, getPackages, getSettings, getpages,
} from '../api';
// eslint-disable-next-line import/no-cycle
import { useStore } from '../context/StoreContext';
import { getDashboardPlans } from '../api/admin';

export function useLocalStorage(key, value = null) {
  const [persistedValue, setPersistedValue] = useState(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue != null) return JSON.parse(storedValue);
    } catch (error) { /* empty */ }

    return value;
  });

  // Sync values to localStorage
  const setPersistedValueDecorator = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
    return setPersistedValue(value);
  };

  return [persistedValue, setPersistedValueDecorator];
}

export function useNaiveLocalStorage(key, value) {
  const getter = useCallback(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue != null) return JSON.parse(storedValue);
    } catch (error) { /* empty */ }

    return value;
  }, []);

  const setter = useCallback((value) => {
    if (typeof value === 'function') return localStorage.setItem(key, JSON.stringify(value(getter())));
    return localStorage.setItem(key, JSON.stringify(value));
  }, []);

  if (getter(key) === null) setter(value);
  return [getter, setter];
}

export function useLocalStore(key, defaultValue) {
  // eslint-disable-next-line prefer-const
  let { [key]: currentValue, dispatch } = useStore();

  if (currentValue === undefined) {
    currentValue = defaultValue;
  }

  const dispatchDecorator = (newValue) => dispatch({ type: 'OVERRIDE_STORE', payload: { [key]: newValue } });

  return [currentValue, dispatchDecorator];
}

function SyncFromLocalStorage(key, initialValues) {
  try {
    let stored = localStorage.getItem(key);
    if (stored !== null) {
      stored = JSON.parse(stored);
      return { ...initialValues, ...stored };
    }
  } catch (error) { /* empty */ }
  return initialValues;
}

export function usePersistedReducer(reducer, initialValues) {
  const [stats, dispatch] = useReducer(reducer, SyncFromLocalStorage('storedStats', initialValues));
  const [, SyncToLocalStorage] = useLocalStorage('storedStats', stats);

  useEffect(() => {
    SyncToLocalStorage(stats);
  }, [stats]);

  return [stats, dispatch];
}

export function useSettings() {
  const { data, ...rest } = useQuery('settings', getSettings, { staleTime: Infinity });

  return { ...rest, settings: data?.settings || {} };
}

// ############################### DASHBOARD HOOKS ######################################

export function useDashboardSettings() {
  const { data, ...rest } = useQuery('admin.settings', getDashboardSettings, { staleTime: Infinity });

  return { ...rest, settings: data?.settings || {} };
}

export function useDashboardPlans() {
  const { data, ...rest } = useQuery('admin.plans', getDashboardPlans, { staleTime: Infinity });
  return { ...rest, plans: data?.plans };
}

export function useDashboardPlan(planId) {
  const { data, ...rest } = useQuery('admin.plans', getDashboardPlans, { staleTime: Infinity });
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [plan] = data?.plans.filter((plan) => plan.id === planId);

  return { ...rest, plan };
}

// ############################### COMMON HOOKS ######################################

export function usePackages() {
  const { data, ...rest } = useQuery('packages', getPackages, { staleTime: 1000 * 60 }); // 1 minute
  const packages = data?.packages;

  return {
    ...rest, packages,
  };
}

export function usePackage(id) {
  const { packages, ...rest } = usePackages();

  return { ...rest, pack: packages?.filter((pack) => pack.id === parseInt(id)).pop() };
}

export function useEventListener(eventName, defaultValue, callback) {
  const [value, setValue] = useState(defaultValue);

  const handleCallback = useCallback((e) => {
    if (typeof callback === 'function') setValue(callback({ e, prevValue: value }));
  }, []);

  useEffect(() => {
    window.addEventListener(eventName, handleCallback);

    return () => {
      window.removeEventListener(eventName, handleCallback);
    };
  }, []);

  return [value, setValue];
}

export function useScrollToRef(defaultValue = null) {
  const ref = useRef(defaultValue);

  const scrollToRef = useCallback(() => {
    setTimeout(() => {
      if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, []);

  return [ref, scrollToRef];
}

export function usePages() {
  const { data, ...rest } = useQuery('pages', getpages, { staleTime: 60000 });

  return { pages: data?.pages, ...rest };
}

export function usePage(slug) {
  const { data, ...rest } = useQuery(`page.${slug}`, () => getPage(slug), { staleTime: 300000, retry: 1 });

  return { page: data?.page, ...rest };
}

export function useClickOutside(callback) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (typeof callback === 'function') {
          callback(event);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback]);

  return ref;
}
