// eslint-disable-next-line import/no-unresolved
import cookieIcon from '@images/cookie-icon.png';
import { useLocalStorage } from '../hooks';

export default function CookiesAlert({
  message = 'Abyśmy mogli lepiej obsłużyć Twoje potrzeby, używamy plików cookies. Korzystając z naszego serwisu, wyrażasz zgodę na ich użycie.',
}) {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('cookies-policy', 0);

  if (cookiesAccepted === 1) return null;

  return (
    <div className="cookie-alert">
      <div className="cookie-img">
        <img
          src={cookieIcon}
          alt="cookie policy icon"
        />
      </div>
      <div className="cookie-policy">
        {message}

        <div className="cookie-buttons">
          <button type="button" className="btn btn-primary cookie-agree-button" onClick={() => setCookiesAccepted(1)}>Akceptuję</button>
        </div>
      </div>
    </div>
  );
}
