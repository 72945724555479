import { memo, useState } from 'react';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import TablerIcon from './TablerIcon';

export default memo(({ skipDemoMode = false, ...restProps }) => {
  const [show, setShow] = useState(false);
  const value = !skipDemoMode ? (restProps?.value ? '**** Hidden for demo mode ****' : '') : restProps?.value;

  return (
    <div className="input-group mb-3">
      <input type={show ? 'text' : 'password'} className="form-control" placeholder="password" aria-label="password" aria-describedby="basic-addon1" {...restProps} value={value} />
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span className="input-group-text text-bg-primary" id="basic-addon1" style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
        <TablerIcon icon={show ? IconEyeClosed : IconEye} />
      </span>
    </div>
  );
});
