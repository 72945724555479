import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default React.memo(React.forwardRef((props, ref) => {
  const {
    isLoading = false, loadingText, children, spinnerClassName = '', ...rest
  } = props;

  return (
    <button type="button" ref={ref} disabled={isLoading} {...rest}>
      {isLoading ? (
        <>
          <FontAwesomeIcon icon={faCircleNotch} spin className={['spinner-border-sm', spinnerClassName].join(' ')} />
          {' '}
          {loadingText}
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
}));
