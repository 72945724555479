import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import SuperButton from './SuperButton';
import { toastFormikErrors } from '../utils';
import { sendPasswordResetEmail } from '../api/auth';

export default function ForgotPasswordModel({ show = false, onHide, setShow }) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Niewłaściwy adres email.'),
    }),
    onSubmit: ({ email }) => {
      sendPasswordResetEmail(email).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          formik.resetForm();
          setShow(!show);
        } else toast.error(data.message);
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
    toastFormikErrors(formik.errors);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Przypomnienie hasła</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Podaj swój adres e-mail:</label>
            <input type="text" id="email" className="form-control" {...formik.getFieldProps('email')} />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <SuperButton type="submit" className="btn btn-primary" isLoading={formik.isSubmitting} onClick={submitForm}>Resetuj hasło</SuperButton>
      </Modal.Footer>
    </Modal>
  );
}
