import { Editor } from '@tinymce/tinymce-react';
import {
  memo, useCallback, useRef,
} from 'react';

const TINY_API_KEY = 'mbsglpl9dg00i9d1jbr71h935b1k05s0ru3c03m3m9tyw56h';

const defaultInitProps = {
  height: 500,
  width: '100%',
  menubar: false,
  content_style: 'body { font-family: DM Sans, Helvetica, Arial, sans-serif; font-size:14px }',
};

export default memo(({ initProps = {}, ...rest }) => {
  const editorRef = useRef(null);
  const currentInitProps = { ...defaultInitProps, ...initProps };

  const handleOnInit = useCallback((evt, editor) => {
    editorRef.current = editor;
  });

  return (
    <Editor
      apiKey={TINY_API_KEY}
      onInit={handleOnInit}
      init={{
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table',
        ],
        toolbar: 'blocks bold italic forecolor | alignleft aligncenter alignright alignjustify | link image media | bullist numlist table outdent indent | removeformat',
        ...currentInitProps,
      }}
      {...rest}
    />
  );
});
