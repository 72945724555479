import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import BasePage from './layouts/BasePage';
import SuperButton from '../components/SuperButton';
import { toastFormikErrors } from '../utils';
import { sendResetPassword } from '../api/auth';

export default function ResetPasswordPage() {
  const { token } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      token,
      new_password: '',
      new_password_confirmation: '',
    },
    validationSchema: Yup.object({
      token: Yup.string().required('Token jest wymagany!'),
      new_password: Yup.string().min(8, 'Hasło musi mieć minimum 8 znaków.')
        .max(40, 'Hasło może mieć maksymalnie 40 znaków.'),
      new_password_confirmation: Yup.string().required('Potwierdzenie hasła jest wymagane.').oneOf([Yup.ref('new_password')], 'Hasła muszą pasować.'),
    }),
    onSubmit: (values) => {
      sendResetPassword(values).then((data) => {
        if (!data.errors) {
          toast.success(data.message);
          formik.resetForm();
          return navigate('/login', { replace: true });
        } toast.error(data.message);
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  return (
    <BasePage>
      <section className="container">
        <div className="row my-5 py-5">
          <div className="col-md-5 m-auto p-5">
            <h1 className="text-center mb-5">Resetowanie hasła</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="new_password" className="form-label">Nowe hasło:</label>
                <input type="password" {...formik.getFieldProps('new_password')} id="new_password" className="form-control form-control-lg" placeholder="+8 znaków" />
              </div>

              <div className="mb-3">
                <label htmlFor="new_password_confirmation" className="form-label">Potwierdź nowe hasło:</label>
                <input type="password" {...formik.getFieldProps('new_password_confirmation')} id="new_password_confirmation" className="form-control form-control-lg" placeholder="+8 znaków" />
              </div>

              <SuperButton isLoading={formik.isSubmitting} onClick={() => toastFormikErrors(formik.errors)} className="btn btn-primary btn-lg btn-block my-4" type="submit" disabled={!formik.isValid}>Zmień hasło</SuperButton>
            </form>
          </div>
        </div>
      </section>
    </BasePage>
  );
}
