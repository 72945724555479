import SectionLoading from '../../../components/SectionLoading';
import { useSettings } from '../../../hooks';
import { useUserOrders } from '../../../hooks/account';
import { datetimeFormat } from '../../../utils';

export default function OrdersPage() {
  const { isLoading, orders } = useUserOrders();
  const { settings } = useSettings();

  if (isLoading) {
    return <SectionLoading center />;
  }

  return (
    <>
      <h1 className="h3 pb-5">Moje zamówienia</h1>

      <table className="table">
        <thead>
          <tr>
            <th>#ID</th>
            <th>Pakiet</th>
            <th>Kwota</th>
            <th>Status</th>
            <th>Data opłacenia</th>
            <th>Data stworzenia</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order, i) => (
            <tr key={i}>
              <td>{order.order_id}</td>
              <td>{order.package_name}</td>
              <td>
                {order.amount}
                {' '}
                {settings.CURRENCY_SYMBOL}
              </td>
              <td>
                {order.status === 1 ? (
                  <span className="badge text-bg-success">opłacono</span>
                ) : (
                  <span className="badge text-bg-warning">nie opłacono</span>
                )}
              </td>
              <td>{order.paid_at ? datetimeFormat(order.paid_at) : '-'}</td>
              <td>{datetimeFormat(order.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
